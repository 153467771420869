import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';
import { Link } from 'gatsby';
import theme from '../theme';

import MUILink from '@mui/material/Link';
import Box from '@mui/system/Box';
import Layout from '../components/Layout';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import VincentVega404 from '../img/error_404.gif';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0),
	position: 'relative',
	height: '100vh',
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
	zIndex: 1,
});

const MainContent = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	// width: '100%',
	zIndex: 1,
	color: theme.palette.background.default,
}));

const NotFoundPage = () => (
	<Layout>
		<Section className='section' px={8}>
			<Box
				sx={{
					// maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					backgroundColor: theme.palette.background.default,
					opacity: '0.8',
					img: {
						height: '100%',
						margin: 0,
						maxWidth: '100%',
						padding: 0,
						position: 'absolute',
						right: '0px',
						top: '0px',
						// width: '60%',
						objectFit: 'cover',
					},
				}}
			>
				<img src={VincentVega404} alt='ALEIGN Distilled' />
			</Box>
			<FrontContent px={8} maxWidth='lg' className='pItem pFnt'>
				<Box className='container' maxWidth='lg' pt={10} pb={14}>
					<Typography variant='h1' align='center'>
						404
						<br />
						PAGE NOT FOUND
					</Typography>
					<Typography variant='h3' align='center'>
						This is not the page you are looking for. <br />
						You can go about your business.
					</Typography>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent='center'
						alignItems='center'
						spacing={3}
						mt={2}
						pb={4}
					>
						<Button
							variant='aleign'
							component={Link}
							to='/'
							// sx={{
							// 	color: theme.palette.primary.main,
							// 	'&:after, &:before': { background: theme.palette.primary.main },
							// 	'&:hover,&:focus,&:active,&.active': {
							// 		color: '#fff',
							// 		backgroundColor: theme.palette.primary.main,
							// 		borderColor: theme.palette.primary.main,
							// 		mx: 4,
							// 	},
							// }}
						>
							Move Along
						</Button>
					</Stack>
				</Box>
			</FrontContent>
		</Section>

		{/* <MainContent className='section' sx={{ pt: 4 }}>
				<img src={VincentVega404} alt='ALEIGN 404' />
				<Typography variant='h1'>NOT FOUND</Typography>
				<Typography>
					You just hit a route that doesn&#39;t exist... the sadness.
				</Typography>
			</MainContent> */}
	</Layout>
);

export default NotFoundPage;
